import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: window.localStorage.getItem('token'),
    admin: window.localStorage.getItem('admin'),
    user: window.localStorage.getItem('user'),
    partnerPortalToken: window.localStorage.getItem('partnerPortalToken'),
  }),

  actions: {
    delete (delVar) {
      delete this[delVar];
      window.localStorage.clear();
      return;
    },
    clearAuth () {
      this.token = null;
      this.admin = null;
      this.user = null;
      this.partnerPortalToken = null;
      window.localStorage.clear();
    },
    setAdmin (isAdmin) {
      this.admin = isAdmin;
      window.localStorage.setItem('admin', isAdmin);
    },
    setPartnerPortalToken (token) {
      window.localStorage.setItem('partnerPortalToken', token);
    },
    setToken (token) {
      this.token = token;
      window.localStorage.setItem('token', token);
    },
    setUser (user) {
      this.user = user;
      window.localStorage.setItem('user', JSON.stringify(user));
    },
    setPartnerPortalToken (token) {
      this.partnerPortalToken = token;
      window.localStorage.setItem('partnerPortalToken', token);
    },
    clearToken () {
      this.delete('token');
    }
  },
});
