import axios from 'axios';

import { useAppStore } from '../../store/app';
import { useAuthStore } from '../../store/auth';
import { useNuxtStore } from '../../store/nuxt';

const config = useRuntimeConfig();

export default {
  data () {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      nuxtStore: useNuxtStore(),
    }
  },
  computed: {
    url () {
      if (!this.nuxtStore.locale) return null; // default url
      if (this.nuxtStore.locale === 'sg') return config.public.SG_API_URL;
      if (this.nuxtStore.locale === 'hk') return config.public.HK_API_URL;
      if (this.nuxtStore.locale === 'au') return config.public.AU_API_URL;
    },
    partnerApiUrl () {
      return config.public.PARTNER_API_URL;
    },
    // in case of emergency, rename this back to user ()
    getUserFromStore () {
      const user = this.authStore.user;
      if (typeof user !== 'string') return user;
      return JSON.parse(user);
    },
  },
  methods: {
    logout () {
      this.$router.push({ path: '/' });
      this.authStore.clearAuth();
      this.appStore.clearAppStore();
      this.nuxtStore.resetDimensions();
      this.nuxtStore.resetLocale();
      window.localStorage.setItem('on-login-redirect', `${window.location.pathname}${window.location.search}`);
      this.$forceUpdate();
    },
    async apiRequest (axiosCfg) {
      let response;
      try {
        if (!this.url) return this.logout();
        axiosCfg.url = this.url + axiosCfg.url;
        response = await axios(axiosCfg)
      } catch (error) {
        if (!error.response) return;
        return new Promise((resolve) => {
          resolve({
            responseCode: error.response.status,
            data: error.response.data || error.response.statusText
          })
          if (error.response.status === 401) this.logout();
          if (error.response.status === 400) {
          }
        })
      }
      return new Promise((resolve) => {
        resolve({
          responseCode: response.status,
          data: response.data
        })
      })
    },
    async partnerApiRequest (axiosCfg) {
      const {
        responseCode: verifyTokenResponse
      } = await this.apiRequest({
        method: 'GET',
        headers: { authorization: this.authStore.token },
        url: '/verify/token',
      });

      if (verifyTokenResponse === 401) return this.logout();

      const isLoginRequest = axiosCfg.url === '/oauth/token';
      let response;

      axiosCfg.url = `${this.partnerApiUrl}${axiosCfg.url}`;

      try {
        if (!this.url) return this.logout();

        response = await axios(axiosCfg);
      } catch (error) {
        return new Promise((resolve) => {
          resolve({
            responseCode: error.response.status,
            data: error.response.data || error.response.statusText
          })

          if (error.response.status === 401 && isLoginRequest) this.logout();
        })
      }
      return new Promise((resolve) => {
        resolve({
          responseCode: response.status,
          data: response.data
        })
      })
    },
    async loginToPartnerAPI (userData) {
      let user = userData;
      if (typeof user === 'string') user = JSON.parse(userData);

      const { email: clientName, userId } = user;

      return await this.partnerApiRequest({
        method: 'POST',
        url: '/oauth/token',
        data: {
          clientName,
          userId,
          region: this.region,
        },
      });
    },
    async retryLoginToPartnerApi () {
      const { data: { access_token: accessToken } } = await this.loginToPartnerAPI(this.getUserFromStore);

      this.authStore.setPartnerPortalToken(accessToken);

      return accessToken;
    },

    async getPartnerApiToken () {
      let partnerToken = this.authStore.partnerPortalToken;

      if (
        partnerToken === undefined ||
        partnerToken === null
      ) {
        partnerToken = await this.retryLoginToPartnerApi();
      }

      return partnerToken;
    }
    // async tokenValid (token) {
    //   return await this.apiRequest({
    //     method: 'get',
    //     url: config.public.API_URL + `/auth/token/${token}`
    //   })
    // }
  },
}
